<template>
  <div>
    <!--begin::Head-->
    <div class="kt-login__head">
      <span class="kt-login__signup-label">Already have an account?</span>&nbsp;&nbsp;
      <router-link class="kt-link kt-login__signup-link" :to="{ name: 'login' }">Login!</router-link>
    </div>
    <!--end::Head-->

    <!--begin::Body-->
    <div class="kt-login__body">
      <!--begin::Signin-->
      <div class="kt-login__form">
        <div class="kt-login__title">
          <h3>Reset your password</h3>
        </div>

        <v-app>
          <v-alert
              v-if="success"
              dense
              text
              type="success"
              v-text="sucuessMsg"
          >

          </v-alert>
        </v-app>

        <!--begin::Form-->
        <b-form class="kt-form" @submit.stop.prevent="onSubmit">
          <b-form-group id="example-input-group-5" label label-for="example-input-5">
            <b-form-input
              type="password"
              id="example-input-5"
              name="example-input-5"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-5-live-feedback"
              placeholder="Password"
              class="border form-control"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback">Password is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group id="example-input-group-6" label label-for="example-input-6">
            <b-form-input
              type="password"
              id="example-input-6"
              name="example-input-6"
              v-model="$v.form.confirmPassword.$model"
              :state="validateState('confirmPassword')"
              aria-describedby="input-6-live-feedback"
              placeholder="Confirm password"
              class="border form-control"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6-live-feedback">Password should match .</b-form-invalid-feedback>
          </b-form-group>

          <!--begin::Action-->
          <div class="kt-login__actions">
            <a href="#" class="kt-link kt-login__link-forgot"></a>
            <b-button
              type="submit"
              id="kt_submit"
              class="btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right"
            >Reset</b-button>
          </div>
          <!--end::Action-->
        </b-form>


        <!--end::Form-->

        <!--begin::Divider-->
<!--        <div class="kt-login__divider">-->
<!--          <div class="kt-divider">-->
<!--            <span></span>-->
<!--            <span>OR</span>-->
<!--            <span></span>-->
<!--          </div>-->
<!--        </div>-->
        <!--end::Divider-->

        <!--begin::Options-->
    <!--     <div class="kt-login__options">
          <a href="#" class="btn btn-primary kt-btn" @click="AuthProvider('facebook')">
            <i class="fab fa-facebook-f"></i>
            Facebook
          </a>

          <a href="#" class="btn btn-danger kt-btn" @click="AuthProvider('google')">
            <i class="fab fa-google"></i>
            Google
          </a>
        </div> -->
        <!--end::Options-->
      </div>
      <!--end::Signin-->
      <vue-recaptcha
        ref="invisibleRecaptcha"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        size="invisible"
      ></vue-recaptcha>
    </div>
    <!--end::Body-->
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { REGISTER } from "@/store/auth.module";
import { LOGOUT, SET_LOGIN } from "@/store/auth.module";
import ApiService from "@/common/api.service";
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import VueRecaptcha from "vue-recaptcha";
/* import VueSocialauth from "vue-social-auth"; */
import { isNullableMobileNumber } from "@/common/validation";
export default {
  components: { VueRecaptcha },
  mixins: [validationMixin],
  name: "register",
  data() {
    return {
      sitekey: process.env.VUE_APP_GOOGLE_CAPTCHA_SITE_KEY,
      token: "",
      email: "",
      success:false,
      successMsg:"",
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(3)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        password: null
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = document.getElementById("kt_submit");
      submitButton.classList.add("kt-spinner");
      ApiService.post("user/do-reset/password", {
        email: this.email,
        token: this.token,
        password: password
      }).then(response => {
        this.success = true;
        this.sucuessMsg = response.data.msg;
        this.$snotify.success(response.data.msg, {
          position: "rightTop",
        });
      });
      // dummy delay
      setTimeout(() => {
        submitButton.classList.remove("kt-spinner");
      }, 2000);

       setTimeout(() => {
        this.$router.push({ name: "login" });
      }, 5000);
    },
    AuthProvider(provider) {
      var self = this;
      this.$auth
        .authenticate(provider)
        .then(response => {
          self.SocialLogin(provider, response);
        })
        .catch(err => {
        });
    },

    SocialLogin(provider, response) {
      ApiService.get("/sanctum/csrf-cookie").then(() => {
        this.$http
          .post("/sociallogin/" + provider, response)
          .then(response => {
            this.$store
              .dispatch(SET_LOGIN, response.data)
              .then(() => this.$router.push({ name: "dashboard" }));
          })
          .catch(err => {
          });
      });
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-2.jpg";
    }
  },
  mounted: function() {},
  created: function() {
    this.token = this.$route.query.token ? this.$route.query.token : "";
    this.email = this.$route.query.email ? this.$route.query.email : "";

    ApiService.post("user/check/reset/password/", {
      token: this.token,
      email: this.email
    })
      .then()
      .catch(({ response }) => {
        this.$snotify.error(response.data.msg, {
          position: "rightTop",
          timeout: 5000,
          showProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true
        });
        setTimeout(() => {
          this.$router.push({ name: "forgot-password" });
        }, 5000);
      });
  }
};
</script>
